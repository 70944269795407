exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-admin-applies-js": () => import("./../../../src/pages/admin/applies.js" /* webpackChunkName: "component---src-pages-admin-applies-js" */),
  "component---src-pages-admin-categories-js": () => import("./../../../src/pages/admin/categories.js" /* webpackChunkName: "component---src-pages-admin-categories-js" */),
  "component---src-pages-admin-checks-js": () => import("./../../../src/pages/admin/checks.js" /* webpackChunkName: "component---src-pages-admin-checks-js" */),
  "component---src-pages-admin-complaints-js": () => import("./../../../src/pages/admin/complaints.js" /* webpackChunkName: "component---src-pages-admin-complaints-js" */),
  "component---src-pages-admin-feedbacks-js": () => import("./../../../src/pages/admin/feedbacks.js" /* webpackChunkName: "component---src-pages-admin-feedbacks-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-locations-js": () => import("./../../../src/pages/admin/locations.js" /* webpackChunkName: "component---src-pages-admin-locations-js" */),
  "component---src-pages-admin-products-js": () => import("./../../../src/pages/admin/products.js" /* webpackChunkName: "component---src-pages-admin-products-js" */),
  "component---src-pages-admin-questions-js": () => import("./../../../src/pages/admin/questions.js" /* webpackChunkName: "component---src-pages-admin-questions-js" */),
  "component---src-pages-admin-quizzes-js": () => import("./../../../src/pages/admin/quizzes.js" /* webpackChunkName: "component---src-pages-admin-quizzes-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-admin-users-requests-js": () => import("./../../../src/pages/admin/users-requests.js" /* webpackChunkName: "component---src-pages-admin-users-requests-js" */),
  "component---src-pages-certification-auditing-js": () => import("./../../../src/pages/certification/auditing.js" /* webpackChunkName: "component---src-pages-certification-auditing-js" */),
  "component---src-pages-contact-apply-js": () => import("./../../../src/pages/contact/apply.js" /* webpackChunkName: "component---src-pages-contact-apply-js" */),
  "component---src-pages-contact-check-js": () => import("./../../../src/pages/contact/check.js" /* webpackChunkName: "component---src-pages-contact-check-js" */),
  "component---src-pages-contact-complaint-js": () => import("./../../../src/pages/contact/complaint.js" /* webpackChunkName: "component---src-pages-contact-complaint-js" */),
  "component---src-pages-contact-feedback-js": () => import("./../../../src/pages/contact/feedback.js" /* webpackChunkName: "component---src-pages-contact-feedback-js" */),
  "component---src-pages-contact-information-js": () => import("./../../../src/pages/contact/information.js" /* webpackChunkName: "component---src-pages-contact-information-js" */),
  "component---src-pages-formation-index-js": () => import("./../../../src/pages/formation/index.js" /* webpackChunkName: "component---src-pages-formation-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-mission-index-js": () => import("./../../../src/pages/our-mission/index.js" /* webpackChunkName: "component---src-pages-our-mission-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */)
}

