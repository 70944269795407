import React from "react"
import {
  ChatBubbleLeftRightIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  InformationCircleIcon,
  ListBulletIcon,
  MapPinIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  TagIcon,
  UserPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import { Link } from "gatsby"
import { useAuthContext } from "../../../contexts/AuthProvider"

export default function Sidebar() {
  const { user, setUser } = useAuthContext()
  return (
    <div className="sidebar pt-[4rem] lg:fixed w-full lg:w-[250px] flex flex-row lg:flex-col lg:flex-nowrap flex-wrap overflow-auto h-full shadow bg-gray-800 text-white">
      <Link to="/admin/" className="flex flex-row gap-4 p-4 no-underline">
        <HomeIcon className="block h-6 w-6 flex-start" aria-hidden="true" />
        <span className="flex-end">Home</span>
      </Link>
      {user.role_as == 1 ? (
        <>
          <Link
            to="/admin/users"
            className="flex flex-row gap-4 p-4 no-underline"
          >
            <UsersIcon
              className="block h-6 w-6 flex-start"
              aria-hidden="true"
            />
            <span>Users</span>
          </Link>

          <Link
            to="/admin/users-requests"
            className="flex flex-row gap-4 p-4 no-underline"
          >
            <UserPlusIcon
              className="block h-6 w-6 flex-start"
              aria-hidden="true"
            />
            <span>Users Request</span>
          </Link>

          <Link
            to="/admin/categories"
            className="flex flex-row gap-4 p-4 no-underline"
          >
            <TagIcon className="block h-6 w-6 flex-start" aria-hidden="true" />
            <span>Categories</span>
          </Link>

          <Link
            to="/admin/products"
            className="flex flex-row gap-4 p-4 no-underline"
          >
            <ListBulletIcon
              className="block h-6 w-6 flex-start"
              aria-hidden="true"
            />
            <span>Products</span>
          </Link>

          <Link
            to="/admin/locations"
            className="flex flex-row gap-4 p-4 no-underline"
          >
            <MapPinIcon
              className="block h-6 w-6 flex-start"
              aria-hidden="true"
            />
            <span>Locations</span>
          </Link>
        </>
      ) : (
        <></>
      )}

      <Link
        to="/admin/feedbacks"
        className="flex flex-row gap-4 p-4 no-underline"
      >
        <ChatBubbleLeftRightIcon
          className="block h-6 w-6 flex-start"
          aria-hidden="true"
        />
        <span>Feedbacks</span>
      </Link>

      <Link
        to="/admin/questions"
        className="flex flex-row gap-4 p-4 no-underline"
      >
        <InformationCircleIcon
          className="block h-6 w-6 flex-start"
          aria-hidden="true"
        />
        <span>Questions</span>
      </Link>

      <Link
        to="/admin/complaints"
        className="flex flex-row gap-4 p-4 no-underline"
      >
        <ExclamationTriangleIcon
          className="block h-6 w-6 flex-start"
          aria-hidden="true"
        />
        <span>Complaints</span>
      </Link>

      <Link to="/admin/checks" className="flex flex-row gap-4 p-4 no-underline">
        <QuestionMarkCircleIcon
          className="block h-6 w-6 flex-start"
          aria-hidden="true"
        />
        <span>Checks</span>
      </Link>

      <Link
        to="/admin/applies"
        className="flex flex-row gap-4 p-4 no-underline"
      >
        <PlusIcon className="block h-6 w-6 flex-start" aria-hidden="true" />
        <span>Applies</span>
      </Link>

      <Link
        to="/admin/quizzes"
        className="flex flex-row gap-4 p-4 no-underline"
      >
        <QuizIcon className="w-6 h-6 flex-start" />
        <span>Quizzes</span>
      </Link>
    </div>
  )
}

const QuizIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    color="currentColor"
    fill="none"
    {...props}
  >
    <path
      d="M7.5 3.5C5.9442 3.54667 5.01661 3.71984 4.37477 4.36227C3.49609 5.24177 3.49609 6.6573 3.49609 9.48836L3.49609 15.9944C3.49609 18.8255 3.49609 20.241 4.37477 21.1205C5.25345 22 6.66767 22 9.49609 22L14.4961 22C17.3245 22 18.7387 22 19.6174 21.1205C20.4961 20.241 20.4961 18.8255 20.4961 15.9944V9.48836C20.4961 6.6573 20.4961 5.24177 19.6174 4.36228C18.9756 3.71984 18.048 3.54667 16.4922 3.5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M7.49609 3.75C7.49609 2.7835 8.2796 2 9.24609 2H14.7461C15.7126 2 16.4961 2.7835 16.4961 3.75C16.4961 4.7165 15.7126 5.5 14.7461 5.5H9.24609C8.2796 5.5 7.49609 4.7165 7.49609 3.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 10L10.5 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.5 11C13.5 11 14 11 14.5 12C14.5 12 16.0882 9.5 17.5 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 16L10.5 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.5 17C13.5 17 14 17 14.5 18C14.5 18 16.0882 15.5 17.5 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
